<template>

    <div>

        <carousel ref="carousel"  :paginationEnabled="false" :perPage="1" :navigationEnabled="false" :autoplay="true" :autoplayHoverPause="true" :loop="true" :autoplayTimeout="7000" :speed="100">

            <template v-if="slidesloaded">


            <!-- are there any slides at all -->
            <template v-if="slidesdata.length==0">
                    <div class="grid-x">
                        <h5 class="cell text-center">Welcome to Oddsgalore</h5>
                        <div class="cell">We compare and analyse bookmaker and exchange odds</div>
                        <div class="cell">You can personalise what odds you see and how you see them</div>
                        <div class="cell">We bring you all the latest hints, odds spots and gossip</div>
                        <div class="cell">And the best new and existing customer bookmakerpromotions</div>
                    </div>
            </template>

            <template v-if="slidesdata.length>0" style="font-weight: bold">

                <template v-for="(row0,i0) in slidesdata" >

                    <template v-if="row0.slide[0].slidertype=='O'">

                     <slide :key="i0">

                        <div style="color:black;background-image: url('/assets/images/carousel/slide1.gif');background-repeat: no-repeat;background-attachment: fixed;background-size: 100% 100%;">

                         <div class="slideheader">Big Events: {{$todayTomorrow(row0.slide[0].event[0].startdate, $dateformattype.daylong)}} {{$displayDate(row0.slide[0].event[0].startdate,$dateformattype.daymonth)}}</div>

                            <div class="grid-x" style="height:230px">

                                <div class="cell auto">

                                    <div style="padding-left:140px">
                                        <div class="grid-x" >

                                            <div class="cell small-4">
                                                <div style="margin-top:50px;font-weight: bold;color:black"></div>
                                                <singleadverts :row="row0.slide[0].event[0]" :backcolour="row0.slide[0].event[0].PromoBackColour"></singleadverts>
                                            </div>

                                            <div class="cell small-7" style="padding: 15px 10px 0px 40px">

                                                <div v-for="(row,i) in row0.slide[0].event" :key=i>

                                                    <div>
                                                        <router-link :to="$URLCreateTS('Competition',row.sport,row.venue,row.competition,row.eventdesc,row.t,row.tpoid,row.compid)">

                                                        <div class="cell small-1 ">
                                                            <div class="grid-x qsupersmallfont qlighttext text-center" style="margin-top:20px">
                                                                
                                                     
                                                                <!-- <div class="cell shrink"><div style="margin-right:4px;margin-top:-1px" class="qwhiteicon" :class="row.sport" ></div></div>
                                                                <div class="cell shrink"><img class="qflagsmall" style="margin-right:4px" :src="'/assets/images/flags/svg/' + row.venue + '.svg'"  :alt="row.venue" /></div> -->
                                                                <div class="cell small-10" style="margin-left:4px"><span style="margin-right:4px"> {{ $displayDate(row.startdate,$dateformattype.timeonly)  }} - </span>{{ row.competition}}</div>
                                                            </div>
                                                        </div>
                                                    
                                                        </router-link>
                                                    </div>
                                                    <div>
                                                       
                                                        <router-link :to="$URLCreateTS(row.urlstub,row.sport,row.venue,row.competition,row.eventdesc,row.t,row.tpoid)">
                                                           <!-- {{$URLCreateTS(row.urlstub,row.sport,row.venue,row.competition,row.eventdesc,row.t,row.tpoid) }} -->
                                                            <div class="cell small-1 qmargintop" >
                                                                <div class="grid-x text-center">
                                                                    <div class="cell small-10 slidertextleft qbordercolour">{{ row.eventdesc  }}</div>
                                                                    <div class="cell small-1 slidertextright qbordercolour"><span style="width:15px;height:15px;background-color:white;border-radius: 50%; display: inline-block;color: #0d0b38; font-size: x-small; font-weight:bold">&#x279C;</span></div>
                                                                </div>
                                                            </div>

                                                        </router-link>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div style="position:fixed;top:170px;padding-left:30px;width: 77px;">
                                        <div style="border-radius: 47px; background-color: snow; height: 45px;"><img style="padding:5px" src="/assets/icons/megafone.png" /></div>
                                       
                                </div>
                             
                        </div>

                        </div>

                    </slide>

                    </template>

                    <template v-if="row0.slide[0].slidertype=='I'">
                        <slide :key="i0">
                            <div  style="color:black"  v-html="getStaticSlide(row0.slide[0].filedatalocation)"></div>
                        </slide>
                    </template>

                    <template v-if="row0.slide[0].slidertype=='P'">
                        <slide :key="i0" style="color:black;">
                            <div style="background-image: url('/assets/images/carousel/slide2.gif');background-repeat: no-repeat;background-attachment:fixed; background-size:100% 100%;">
                             <!--positioned stuff -->
                             <div class="slideheader">{{ langConv('New Customer Offers') }}</div>

                            <div class="grid-x" style="padding-top:50px">

                                  <!-- <div class="cell shrink">
                                        <div style="height: 100px;"></div>
                                        <div style="width: 0; height: 0; border-top: 45px solid transparent; border-right: 20px solid lightgray; border-bottom: -25px solid transparent;">&nbsp;</div>
                                </div> 
                                <div  class="cell small-2" style="border-radius: 100px 0px 0px 120px; background-color: lightgrey;">&nbsp;</div> -->
                                <div class="cell auto">
                                
                                <div style="padding-left:140px">

                                <div class="cell small-12"> 

                                   <div class="grid-x" style="padding-right:15px; margin-left:-20px">
                                        <template v-for="(row,i) in row0.slide[0].promos" >

                                            <div class="cell small-4" style="font-size:x-small; color:black; font-weight: bold; padding-right:10px; " :key=i >

                                                <singleadverts :row="row" :backcolour="row.BackColour"></singleadverts>

                                            </div>

                                        </template>
                                    </div>

                                 </div>
                                 </div>
                                 </div>
                                 </div>

                                <div style="position:fixed;top:170px;padding-left:30px;width: 77px;">
                                        <div style="border-radius: 47px; background-color: snow; height: 45px;"><img style="padding:5px" src="/assets/icons/megafone.png" /></div>
                                </div>

                                </div>

                        </slide>
                    </template>

                    <template v-if="row0.slide[0].slidertype=='W'" style="color:black">

                        <slide style="color:#0d0b38" :key="i0">
                            <div style="background-image: url('/assets/images/carousel/slide4.gif');background-repeat: no-repeat;background-attachment:fixed; background-size:100% 100%;">
                                <!--positioned stuff -->

                                 <div class="slideheader">{{row0.slide[0].winner[0].CompDesc}}</div>
                               
                            <div class="grid-x" style="height:230px" >
                             
                                <div class="cell auto">
                                <div style="padding-left:140px">

                                    <div class="grid-x" >

                                    <div class="cell small-4" style="font-size:small">
                                        <div style="margin-top:50px;font-weight: bold;color:white"></div>

                                        <singleadverts :row="row0.slide[0].winner[0]" :backcolour="row0.slide[0].winner[0].PromoBackColour"></singleadverts>

                                    </div>

                                    <div class="cell small-7" style="padding: 0px 10px 0px 40px">

                                        <div class="slidertext" style="line-height:14px; margin:5px" v-for="(row,i) in row0.slide[0].winner[0].event" :key=i>
                                            <div class="grid-x">
                                                <div class="cell small-1"></div>
                                                <div class="cell small-8 slidertextleft" >{{row.Competitor}}</div>
                                                <div class="cell small-1"></div>
                                                <div class="cell auto slidertextleft" >{{row.Odds}}</div> 
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div style="position:fixed;top:170px;padding-left:30px;width: 77px;">
                                        <div style="border-radius: 47px; background-color: snow; height: 45px;"><img style="padding:5px" src="/assets/icons/megafone.png" /></div>
                                </div>

                            </div>
                      
                        </slide>
                    </template>

                </template>

            </template>

            </template>


        </carousel>

            <template v-if="slidesloaded&&slidesdata.length>0">
                <div class="grid-x">
                    <template v-for="(rowa,ia) in slidesdata">

                        <template v-if="rowa.slide[0].slidertype=='O'">

                            <div class="cell auto" :key="ia" >
                                <div id="basicslider" v-bind:class="[{ currentslider: currentSlide(Math.floor(ia)), noncurrentslider: !currentSlide(Math.floor(ia))}]" v-on:click="goToSlide(Math.floor(ia));">
                                   <div>{{$displayDate(rowa.slide[0].event[0].startdate, $dateformattype.day)}}
                                    <span style="font-size:x-small">{{$displayDate(rowa.slide[0].event[0].startdate,$dateformattype.daymonth)}}</span>
                                   </div>
                                </div>
                            </div>

                        </template>

                    <template v-if="rowa.slide[0].slidertype=='I'">
                            <div class="cell auto" :key="ia" >
                                <div id="basicslider" v-bind:class="[{ currentslider: currentSlide(Math.floor(ia)), noncurrentslider: !currentSlide(Math.floor(ia))}]" v-on:click="goToSlide(Math.floor(ia));">
                                   <div v-html="langConv(rowa.slide[0].tabname)"></div>
                                </div>
                            </div>


                    </template>

                    <template v-if="rowa.slide[0].slidertype=='P'">
                            <div class="cell auto" :key="ia" >
                                <div id="basicslider" v-bind:class="[{ currentslider: currentSlide(Math.floor(ia)), noncurrentslider: !currentSlide(Math.floor(ia))}]" v-on:click="goToSlide(Math.floor(ia));">
                                   <div v-html="langConv(rowa.slide[0].tabname)"></div>
                                </div>
                            </div>

                    </template>

                    <template v-if="rowa.slide[0].slidertype=='W'">
                            <div class="cell auto" :key="ia" >
                                <div id="basicslider" v-bind:class="[{ currentslider: currentSlide(Math.floor(ia)), noncurrentslider: !currentSlide(Math.floor(ia))}]" v-on:click="goToSlide(Math.floor(ia));">
                                   <div v-html="langConv(rowa.slide[0].tabname)"></div>
                                </div>
                            </div>
                    </template>

                    </template>
                </div>
            </template>

        </div>


</template>

<script>


   import { Carousel, Slide } from 'vue-carousel';

   import singleadverts from '/src/components/adverts/singleadverts.vue'

   export default {
        name: "adcarousel",
        components: {
            Carousel,
            Slide,
            singleadverts
        },
        data() {
            return {
                images: [],
                slides: null,
                slidesdata: null,
                slidesloaded: false,
                img: ['/assets/icons/bet-whi.png','/assets/icons/analytics-whi.png','/assets/icons/bookmaker-whi.png','/assets/icons/horses-mouth-whi.png','/assets/icons/knowledge-whi.png','/assets/icons/betcalc.png'],
                url: ['./Graph','./OddsAnalysis','./bookmakercomparison','./horsesmouth/1','./knowledgebase','./Calculators/HutchDutch'],
                title: ['We publish our bets and strategies for you to view', 'We analyse the odds to help with different betting strategies','We compare bookmakers to highlight their pros and cons',
                'We update you with the latest promotions and news','We share what we know about all things gambling','Use our Bet Calculator to access and store your bets'],
                featurecounter: 0,
                intervalID: undefined
            }
        },
        computed: {
            currentIndex: {
                cache: false,
                get() {
                    return this.$refs.carousel ? this.$refs.carousel.currentPage : 0;
                }
            }
        },
        mounted() {

            this.pageChange(0);  
            
           
        },

        created() {
            try {
                this.getSlideData();
            } catch (e) {
                console.log(e);
            }  
        },

        methods: {

            
            HTMLClicked: function (val) {
                //before moving off cancel timer
             
                clearInterval(this.intervalID)
                this.intervalID == undefined;
                this.$router.push(this.url[this.featurecounter-1])
            },


           pageChange: function (page) {

                          
                //turn interval on and off if not page 0
                if (page != 0) {
                    //run updateScreen Manually for the first time
                    clearInterval(this.intervalID)
                    this.intervalID == undefined;
                    
                } else {
                    
                    this.intervalID = setInterval(this.updateScreen,4000);  
                }

            },
   
            updateScreen: function() {
                try {
                //reset counter if 2
                if (this.featurecounter == 2) this.featurecounter = 0;
                    let item1 = (this.featurecounter * 3);
                    let item2 = item1 + 1;
                    let item3 = item2 + 1;

                    //change text                    
                    document.getElementById('title0').innerHTML = this.langConv(this.title[item1]);
                    document.getElementById('title1').innerHTML = this.langConv(this.title[item2]);
                    document.getElementById('title2').innerHTML = this.langConv(this.title[item3]);

                    //change image
                    document.getElementById('img0').src = this.img[item1];
                    document.getElementById('img1').src = this.img[item2];
                    document.getElementById('img2').src = this.img[item3];

                     //change URL
                    document.getElementById('go0').href = this.url[item1];
                    document.getElementById('go1').href = this.url[item2];
                    document.getElementById('go2').href = this.url[item3];

                this.featurecounter++
                } catch {
                    clearInterval(this.intervalID)
                    this.intervalID == undefined;
                }
            },




            getArrayValue: function (arr,value){
                        return arr[0][value]
            },
            imageURL: function (val1, val2) {
                    return val1 + val2
            },


            goToSlide: function (slideNo) {
                this.$refs.carousel.goToPage(slideNo);
            },

            currentSlide: function (slideNo) {

                var bn = false;

                if (slideNo == this.currentIndex) {
                    bn = true;
                }

                return bn;

            },

            getStaticSlide: function (filename) {

                

               return require("/public/assets/slides/" + filename);

            },

            //get data for banners
            getSlideData: function () {

                try {

                //does the country have a language version of the slider 21/12/2023 - Test for Germany only
                var country =  this.$store.state.countryname;

                country = this.backtoEnglish(country);

                this.localJSONLoad(process.env.VUE_APP_DATA + "slider " + country + ".json").then(response => {
                    this.slidesdata = response
                    this.slidesLoaded()
               }).catch(error => {
                   console.log(error)
               });

            } catch (e) {
                console.log(e)
            }

            },

            slidesLoaded: function () {
                this.slidesloaded = true;
                this.$emit("slides-loaded");
            }
        }
    }
</script>

<style>


    @import "../../style/bkmsmall.css";

   .VueCarousel-slide {
        position: relative;
        display: inline-block; /* Make the width of box same as image */
        color: #fff;
        height: 230px;
    }

    #bg {
        width: 100% !important;
        height: 100% !important;
        background-size: cover !important;
    }

    .slidertext {
       
        background-color: #0d0b38  ;
        color: white;
        padding:3px;
        font-size: small;
        font-weight: bold;
        border-radius: 10px;
    }

        .slidertextleft {
        font-family:"roboto condensed";
        color: white;
        padding:3px;
        font-size: 13px;
        font-weight:400;
        border-radius: 10px 0px 0px 10px;

    }

    .slidertextright {
      
        color: white;
        padding:3px;
        font-weight: 500;
        font-size: small;
        border-radius: 0px 10px 10px 0px;
    }


    #basicslider {
        
      font-size: 12px;
      
        background-color: rgba(202, 198, 198, 0.4);
        color: #0d0b38;
       
        padding: 6px;

    }

    .currentslider {
       
        border-bottom: 3px solid gold;
        border-radius: 0px 0px 5px 5px;
        border-right: white solid 1px;
        cursor: pointer;
    }

    .noncurrentslider {
     
        /* border-top: 2px solid grey; */
        border-radius: 0px 0px 5px 5px;
        border-right: white solid 1px;
        cursor: pointer;
    }



.VueCarousel-navigation-button {
    /* background-color:#eee !important ; */
    /* padding: 3px !important; */
    /* border-radius: 50%; */
    /* color: darkslategray !important; */
    /* border: 1px solid darkslategrey !important; */
   

    font-size: x-small !important;
}






    .slideheader {position:absolute; left:40px;top: 10px; background-color:#ffcd03;border-radius:20px 20px 20px 20px; padding:4px 10px 4px 15px;font-weight:600; font-size:13px;}

</style>
